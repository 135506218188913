import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import IndexLayout from '../components/PagesLayout/index';

const Index = ({ data, location }) => {
  return <IndexLayout data={data} location={location} />;
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
         query IndexQuery($locale: String!) {
           homepage: prismicHomePage(lang: { eq: $locale }) {
             data {
               title {
                 html
               }
               subheadline {
                 text
               }
               hero_body {
                 html
               }
               hero_image {
                 desktop: localFile {
                   childImageSharp {
                     fluid(toFormat: JPG) {
                       ...GatsbyImageSharpFluid_noBase64
                     }
                   }
                 }
                 mobile: localFile {
                   childImageSharp {
                     fluid(toFormat: JPG) {
                       ...GatsbyImageSharpFluid_noBase64
                     }
                   }
                 }
               }
               expertise {
                 document {
                   data {
                     title {
                       text
                     }
                     body {
                       html
                     }
                     service_block {
                       service_title {
                         html
                         text
                       }
                       service_body {
                         html
                       }
                       service_link {
                         uid
                         url
                       }
                     }
                   }
                 }
               }
             }
           }
           projectsContent: prismicProjectTile(lang: { eq: $locale }) {
             data {
               flags_selector {
                 country
                 longitude
                 latitude
                 flag_image {
                   url
                 }
               }
             }
           }
           articles: prismicArticlesSection(lang: { eq: $locale }) {
             data {
               title {
                 text
               }
               load_more_button_label {
                 text
               }
               other_articles {
                 text
               }
             }
           }
           allPrismicArticleIndividual(filter: { lang: { eq: $locale } }) {
             edges {
               node {
                 uid
                 lang
                 data {
                   title {
                     text
                   }
                   top_priority
                   photo_vertical {
                     url
                     desktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_vertical {
                     url
                     mobile: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_horizontal {
                     url
                     desktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_horizontal {
                     url
                     mobile: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   industry_selector_fr
                   industry_selector_eng
                   highlight_on_homepage
                   excerpt {
                     html
                   }
                   date(formatString: "")
                   countries {
                     country
                   }
                   body {
                     text
                   }
                   tags {
                     text
                   }
                 }
               }
             }
           }
           projects: prismicProjectTile(lang: { eq: $locale }) {
             data {
               project_page_title {
                 text
               }
               project_page_body {
                 text
               }
             }
           }
           allPrismicProjectSection(filter: { lang: { eq: $locale } }) {
             edges {
               node {
                 lang
                 uid
                 data {
                   countries {
                     country
                   }
                   download_project_pdf {
                     url
                   }
                   highlight_on_homepage
                   industry_selector_eng
                   industry_selector_fr
                   excerpt {
                     text
                   }
                   photo_horizontal {
                     url
                     desktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_horizontal {
                     url
                     mobile: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   project_sections {
                     include_section_on_homepage_project_tile
                     project_section_body {
                       text
                     }
                     project_section_title {
                       text
                     }
                   }
                   tags {
                     text
                   }
                   title {
                     text
                   }
                 }
               }
             }
           }
         }
       `;
